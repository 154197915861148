import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"

import "bootstrap/dist/css/bootstrap.css"

import LogoGraphic from "../images/icons/logo_graphic.png"
import LogoWhite from "../images/icons/logo_graphic_white.jpg"

import SEO from "../components/seo"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

const IndexPage = () => (
  <Container className="mt-5 pt-5 flex-column align-items-center justify-content-center">
   <SEO title="Under Construction" />
   <Helmet>
    <meta property="og:title" content="Grace Care Village" />
    <meta property="og:image" content={ LogoWhite } />
   </Helmet>
   <Row className="mt-5"></Row>
   <Row className="justify-content-center">
      <img
        src={LogoGraphic} alt=""
        style={{width: "160px", height: "140px"}}
      />
    </Row>
    <Row className="d-flex text-center">
      <h2>
        Grace Care Village
      </h2>
      <p className="mt-1">
        Our new website is under construction. <br /> Stay tuned!
      </p>
    </Row>
  </Container>
)

IndexPage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default IndexPage
